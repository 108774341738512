<template>
  <div class="text-charcoal-grey bg-pure-white flex flex-col">
    <div class="relative">
      <div class="absolute pin flex flex-column bg-white">
        <div class="w-full h-85 bg-pure-white"></div>
        <div class="bg-white"></div>
      </div>
      <SearchSteps
          ref="search-steps"
          class="relative"
      />
    </div>
    <div
        class="sticky-search-top flex flex-row sticky border-t border-white z-10"
        :class="{ 'shadow': addBoxShadow }"
    >
      <div class="pl-40 w-full h-70 bg-pure-white flex flex-row items-center justify-between">
        <div>
          <span class="font-sans-bold text-faded-red text-xl">
            {{ filteredProductsBySubcategory.length }}
          </span>
          <span class="text-xl font-sans-regular text-faded-red">
            {{ $t('search_results.results_found_text') }}
          </span>
        </div>
        <div
            class="flex flex-row items-center"
            @click="filterIsVisible = !filterIsVisible"
        >
          <p
              :class="[filterIsVisible ? 'text-faded-red' : 'text-charcoal-grey']"
              class="font-sans-bold cursor-pointer"
          >
            {{ $t('search_results.filter_text') }}
          </p>
          <div class="mr-40 ml-20">
            <IconCross
                v-if="filterIsVisible"
                :class="[filterIsVisible ? 'text-faded-red' : 'text-charcoal-grey']"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 bg-white overflow-x-hidden">
      <div class="w-full h-full flex flex-1">
        <div :class="[filterIsVisible ? 'w-1/2 tablet-landscape:w-2/3' : 'w-full']">
          <ProductGrid
              :products="filteredProductsBySubcategory"
              class="p-40"
              :class="productGridClassNames"
          />
        </div>
        <div :class="{ 'w-1/2 tablet-landscape:w-1/3' : filterIsVisible }">
          <FilterPanel
              :filter-is-visible="filterIsVisible"
              @filter-panel-closed="changeGridColumnCount"
              @selected-sub-category-changed="filterProductsBySubCategories($event)"
          />
        </div>
      </div>
      <p
          v-if="allProductsIsLoading"
          class="text-center text-sm bg-white text-charcoal-grey pb-40"
      >
        {{ $t('search_results.more_results_loading_text') }} …
      </p>
    </div>

    <SearchModal />
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import { debounce } from 'lodash';
import SearchSteps from '@/components/search/SearchSteps';
import ProductGrid from '@/components/product/ProductGrid';
import ProductGridItem from '@/components/product/ProductGridItem';
import FilterPanel from '@/components/FilterPanel';
import IconCross from '@/components/icon/IconCross';
import SearchModal from '@/components/search/SearchModal';


export default {
  components: {
    SearchSteps,
    ProductGrid,
    ProductGridItem,
    FilterPanel,
    IconCross,
    SearchModal,
  },
  data() {
    return {
      activeTab: 0,
      filterIsVisible: true,
      addBoxShadow: false,
      isFirstLoading: true,
      productGridClassNames: [],
      filteredProductsBySubcategory: [],
      underTabValues: {
        decke: {
          image: '/images/icons/assembly-types/small/ceiling.svg',
        },
        steildach: {
          image: '/images/icons/assembly-types/small/steep-roof.svg',
        },
        aussenwand: {
          image: '/images/icons/assembly-types/small/outside-wall.svg',
        },
        trennwand: {
          image: '/images/icons/assembly-types/small/dividing-wall-single.svg',
        },
        twzweischalig: {
          image: '/images/icons/assembly-types/small/dividing-wall-double.svg',
        },
      },
    };
  },
  async created() {
    window.addEventListener('scroll', this.scrollListener);

    // Loading products and applying search filters if they are not already loaded
    // and user selected data is present
    if (this.$store.state.assemblySearch.selectedValues.assemblyType
      && !this.$store.state.assemblySearch.filteredProducts.length) {
      await this.updateAllProducts();
      this.applySearchFilters();
    } else if (!this.$store.state.assemblySearch.selectedValues.assemblyType) {
      this.$router.push({
        name: 'HomeView',
      });
    }

    // By default filteredProducts are shown
    this.filteredProductsBySubcategory = this.filteredProducts;
  },
  watch: {
    filterIsVisible: {
      immediate: true,
      handler(newValue) {
        if (!newValue && !this.isFirstLoading) return;

        this.isFirstLoading = false;
        this.changeGridColumnCount();
      },
    },
  },
  computed: {
    ...mapState('assemblySearch', {
      filteredProducts: 'filteredProducts',
      allProductsIsLoading: 'allProductsIsLoading',
    }),
  },
  methods: {
    ...mapActions('ui', {
      updateStickyNavTopShadowIsVisible: 'updateStickyNavTopShadowIsVisible',
    }),
    ...mapActions('assemblySearch', {
      updateAllProducts: 'updateAllProducts',
      applySearchFilters: 'applySearchFilters',
    }),
    changeGridColumnCount() {
      if (!this.filterIsVisible) {
        this.productGridClassNames = ['grid-template-columns-2', 'tablet-landscape:grid-template-columns-3'];
        return;
      }
      this.productGridClassNames = ['grid-template-columns-1', 'tablet-landscape:grid-template-columns-2'];
    },
    // eslint-disable-next-line func-names
    scrollListener: debounce(function () {
      if (window.pageYOffset >= this.$refs['search-steps'].$el.clientHeight) {
        this.addBoxShadow = true;
        this.updateStickyNavTopShadowIsVisible(false);
      } else {
        this.addBoxShadow = false;
        this.updateStickyNavTopShadowIsVisible(true);
      }
    }, 250, { maxWait: 100 }),
    filterProductsBySubCategories(selectedFilters) {
      const filteredProductsBySubcategory = [];

      if (!selectedFilters.length) {
        this.filteredProductsBySubcategory = this.filteredProducts;
        return;
      }

      this.filteredProducts.forEach((filteredProduct) => {
        const productCategoryOptions = filteredProduct.data.categoryOptions;

        for (let i = 0; i < selectedFilters.length; i += 1) {
          if (productCategoryOptions[selectedFilters[i].categoryValue] !== undefined
            && productCategoryOptions[selectedFilters[i].categoryValue]
            === selectedFilters[i].subCategoryValue) {
            filteredProductsBySubcategory.push(filteredProduct);
            break;
          }
        }
      });

      // console.log(this.filteredProductsBySubcategory);
      this.filteredProductsBySubcategory = filteredProductsBySubcategory;
    },
  },
  beforeDestroy() {
    this.updateStickyNavTopShadowIsVisible(true);
    window.removeEventListener('scroll', this.scrollListener);
  },
};
</script>


<style lang="scss" scoped>
@import '../styles/variables';

.sticky-search-top {
  top: $sticky-nav-top-h;
}
</style>

<template>
  <Transition
      name="slide"
      @after-leave="$emit('filter-panel-closed')"
  >
    <div
        v-if="filterIsVisible"
        class="bg-pure-white h-full"
    >
      <div
          v-for="(categoryOption, catIndex) in categoryOptions"
          :key="`category-option-${catIndex}`"
          class="p-24 mb-2 bg-pure-white border-t border-white"
      >
        <div>
          <p class="font-sans-bold uppercase text-charcoal-grey">
            {{ categoryOption.label }}
          </p>
        </div>
        <div
            v-for="(subCategoryOption, index) in categoryOption.subCategoryOptions"
            :key="`sub-category-option-${catIndex}-${index}`"
            class="flex flex-row mt-17 w-full"
            :class="{
                'text-faded-red':
                subCategoryIsSelected(categoryOption.value, subCategoryOption.value)
            }"
        >
          <div
              class="flex flex-row w-full items-center justify-between cursor-pointer select-none"
              @click="onSubcategoryPress(categoryOption.value, subCategoryOption.value)"
          >
            <p class="mr-20">{{ subCategoryOption.label }}</p>
            <IconCheck
                v-if="subCategoryIsSelected(categoryOption.value, subCategoryOption.value)"
                class="flex-no-shrink"
            />
            <p
                v-else-if="subCategoryOption.count"
                class="w-20 h-20 text-center text-cool-grey"
            > 
              {{ subCategoryOption.count }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import IconCheckbox from '@/components/icon/IconCheckbox';
import IconCheck from '@/components/icon/IconCheck';


export default {
  props: {
    filterIsVisible: Boolean,
  },
  components: {
    IconCheckbox,
    IconCheck,
  },
  data: () => ({
    selectedValues: [],
    categoryOptionCounts: {},
  }),
  watch: {
    selectedValues: {
      handler(newValue) {
        this.$emit('selected-sub-category-changed', newValue);
      },
    },
    filteredProducts: {
      immediate: true,
      handler(newValue) {
        if (!newValue.length || !this.categoryOptions.length) return;

        this.setProductSubCategoryCounts();
      },
    },
    categoryOptions: {
      immediate: true,
      handler(newValue) {
        if (!newValue.length || !this.filteredProducts.length) return;

        this.setProductSubCategoryCounts();
      },
    },
  },
  computed: {
    ...mapState('assemblySearch', {
      filteredProducts: 'filteredProducts',
    }),
    ...mapGetters('assemblySearch', {
      selectedAssemblyType: 'selectedAssemblyType',
    }),
    categoryOptions() {
      if (this.selectedAssemblyType === undefined
        || this.selectedAssemblyType.data.categoryOptions === undefined) return [];

      return this.selectedAssemblyType.data.categoryOptions.map(categoryOption => ({
        label: categoryOption[this.$i18n.locale].categoryName,
        value: categoryOption.value,
        subCategoryOptions: categoryOption.subCategoryValues
          .map((subCategoryValue, index) => ({
            label: categoryOption[this.$i18n.locale].subCategoryNames[index],
            value: subCategoryValue,
          })),
      }));
    },
  },
  methods: {
    onSubcategoryPress(categoryValue, subCategoryValue) {
      const selectedSubCategoryIndex = this.selectedSubCategoryIndex(categoryValue,
        subCategoryValue);
      if (selectedSubCategoryIndex !== -1) {
        this.selectedValues.splice(selectedSubCategoryIndex, 1);
        return;
      }

      this.selectedValues.push({
        categoryValue,
        subCategoryValue,
      });
    },
    selectedSubCategoryIndex(categoryValue, subCategoryValue) {
      return this.selectedValues
        .findIndex(selectedValue => selectedValue.categoryValue === categoryValue
          && selectedValue.subCategoryValue === subCategoryValue);
    },
    subCategoryIsSelected(categoryValue, subCategoryValue) {
      return this.selectedSubCategoryIndex(categoryValue, subCategoryValue) !== -1;
    },
    setProductSubCategoryCounts() {
      const categoryOptionCounts = {};

      // Set defaults
      this.categoryOptions.forEach((categoryOption) => {
        categoryOption.subCategoryOptions.forEach((subCategoryOption) => {
          if (categoryOptionCounts[categoryOption.value] === undefined) {
            categoryOptionCounts[categoryOption.value] = {};
          }
          categoryOptionCounts[categoryOption.value][subCategoryOption.value] = 0;
        });
      });

      this.filteredProducts.forEach((filteredProduct) => {
        const productCategoryOptions = filteredProduct.data.categoryOptions;

        this.categoryOptions.forEach((categoryOption) => {
          categoryOption.subCategoryOptions.forEach((subCategoryOption) => {
            if (productCategoryOptions[categoryOption.value] !== undefined
              && productCategoryOptions[categoryOption.value] === subCategoryOption.value) {
              categoryOptionCounts[categoryOption.value][subCategoryOption.value] += 1;
            }
          });
        });
      });

      this.categoryOptions.forEach((category, index) => {
        Object.keys(categoryOptionCounts[category.value]).forEach(subCategoryCounts => {
          category.subCategoryOptions.forEach((subCategory, subCategoryIndex) => {
            if (subCategoryCounts === subCategory.value && categoryOptionCounts[category.value][subCategoryCounts] !== 0) {
              subCategory['count'] = categoryOptionCounts[category.value][subCategoryCounts];
            } else if (subCategoryCounts === subCategory.value && categoryOptionCounts[category.value][subCategoryCounts] === 0) {
              category.subCategoryOptions.splice(subCategoryIndex, 1);
            }
          });
        });
        if (!category.subCategoryOptions.length) {
          this.categoryOptions.splice(index, 1);
        }
      });

      this.categoryOptionCounts = categoryOptionCounts;
      this.$forceUpdate();
    },
  },
};
</script>


<style lang="scss" scoped>
@import '../styles/variables';

.slide-leave-active, .slide-enter-active {
  transition: 0.3s;
}

.slide-enter, .slide-leave-to {
  transform: translateX(100%);
}
</style>
